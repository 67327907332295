import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { omit, uniq } from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  generateParamArray,
  generateSearchQuery,
} from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { DateFormatterService } from '../core/services/date-formatter.service';
import { IAddRoomRequest } from '../features/commons/reservation-details/models/reservation-add-rooms';
import { ReservationPriceChangeRequest } from '../features/commons/reservation-details/models/reservation-price-change-request';
import { ICustomerReservationAttach } from '../features/commons/reservation-details/models/reservation.guests.model';
import { UpdateReservationAccommodationMoreRequest } from '../features/commons/reservation-details/models/update-reservation-accommodation-more.request';
import { observableSequence, removeNullishValues } from '../helpers';
import {
  CreateScaConfirmationRequestRequest,
  DailyUpdateRequest,
  IShowCreditCardRequest,
  ISplitReservation,
  IStatusUpdate,
  Reservation,
  ReservationTotals,
  UpdateCityTaxReportExclusionRequest,
} from '../models';
import { UpdateReservationCheckinCheckout } from '../models/requests/update-reservation-checkin-checkout.request';
import { UpdateReservationKeepAccommodationRequest } from '../models/requests/update-reservation-keep-accommodation.request';

import { PaymentsService } from './payments.service';

@Injectable({ providedIn: 'root' })
export class ReservationDetailsService {
  constructor(
    private http: HttpClient,
    private dateFormatter: DateFormatterService,
    private reservationPaymentService: PaymentsService,
  ) {}

  load(reservationId: number) {
    return this.http.get<IResponseSuccess<Reservation[]>>(
      `reservation/${reservationId}`,
      {
        params: {
          reference_date: this.dateFormatter.toServerFormat(new Date()),
        },
      },
    );
  }

  loadTotals(reservationId: number) {
    return this.http.get<IResponseSuccess<ReservationTotals[]>>(
      `reservation/${reservationId}/total_details`,
    );
  }

  updateCheckinCheckout(data: UpdateReservationCheckinCheckout) {
    const { checkinStatus, roomreservation_id, reservation_id } = data;
    return this.http.put(
      `reservation/${reservation_id}/checkin_checkout/${roomreservation_id.replace(
        reservation_id.toString(),
        '',
      )}`,
      omit(checkinStatus, 'res_accommodation_room_id'),
    );
  }

  loadReservationAccommodationMore(
    reservationID: number,
    roomReservationID?: string[],
  ) {
    return this.http.get(
      `reservations/${reservationID}/accommodations/more?${generateSearchQuery(
        removeNullishValues({
          roomreservation_id: roomReservationID,
        }),
      )}`,
    );
  }

  updateReservationAccommodationMore(
    data: UpdateReservationAccommodationMoreRequest,
  ) {
    const { roomreservation_id, reservation_id, ...request } = data;
    return this.http.post(
      `reservations/${reservation_id}/accommodations/${roomreservation_id}/more`,
      request,
    );
  }

  updateNotes(
    reservationId: number,
    type: string,
    noteData: string,
    reservation_accommodation_id?: number,
  ) {
    return this.http.put(
      `reservation/${reservationId}/notes/${
        reservation_accommodation_id
          ? 'accommodation/' + reservation_accommodation_id + '/'
          : ''
      }${type}`,
      { note: noteData },
    );
  }

  createScaConfirmationRequest(request: CreateScaConfirmationRequestRequest) {
    return this.http.post('sca', request);
  }

  updateCheckInOutDate(data: {
    reservationId: number;
    accommodations;
    typeRequest: 'checkin' | 'checkout' | 'checkinChekout';
    force_operation?: boolean;
    model_price?: 'moved' | 'original';
  }) {
    const {
      accommodations,
      force_operation,
      model_price,
      reservationId,
      typeRequest: type,
    } = data;
    let payload: any = { accommodations };
    if (force_operation) {
      payload = {
        ...payload,
        force_operation: force_operation,
        model_price: model_price,
      };
    }
    if (type === 'checkin' || type === 'checkout') {
      return this.http.put(
        `reservation/${reservationId}/update_check${
          type === 'checkin' ? 'in' : 'out'
        }_date`,
        payload,
      );
    } else {
      return this.http.put(
        `reservation/${reservationId}/update_checkin_checkout_date`,
        payload,
      );
    }
  }

  updatePaymentMethod(reservationId: number, data: any) {
    if (data.reservation_payment_id) {
      return this.reservationPaymentService.update(
        reservationId,
        data.reservation_payment_id,
        data,
        true,
      );
    }
    return this.http.put(`reservation/${reservationId}/payment_method`, data);
  }

  updateStatus(data: IStatusUpdate) {
    return this.http.put(`reservation/${data.reservation_id}/status`, data);
  }

  updateReservationDetails(reservationId: number, reservationData: any) {
    return this.http.put(`reservation/${reservationId}`, reservationData);
  }

  updateCustomer(reservationId: number, customerData: any) {
    return this.http.put(`reservation/${reservationId}/customer`, customerData);
  }

  splitReservation(data: ISplitReservation) {
    return this.http.post(
      `tableau/split_reservation_accommodation_multi`,
      data,
    );
  }

  setKeepAvailability(keep_availability: boolean, reservation_id: number) {
    return this.http.put(
      `reservation/${reservation_id}/set_keep_availability`,
      { keep_availability: +keep_availability },
    );
  }

  loadMedias(accommodationIds: number[]) {
    return forkJoin(
      uniq(accommodationIds).map(
        (accommodationId) =>
          this.http.get(
            `media/accommodation/${accommodationId}?all_size=1`,
          ) as Observable<IResponseSuccess>,
      ),
    ).pipe(
      map((response) => {
        return uniq(accommodationIds).reduce((acc, curr, index) => {
          const data = response[index].data;
          if (data) {
            acc[curr] = data;
          }
          return acc;
        }, {});
      }),
    );
  }

  loadLogs(reservationId: number) {
    return this.http.get(`reservation/history/${reservationId}`);
  }

  loadWarnings(reservationId: number) {
    return this.http.get(`reservation/${reservationId}/warnings`);
  }

  attachCustomerDetails(
    data: ICustomerReservationAttach,
    type: 'edit' | 'new' | 'delete',
    force_operation: boolean,
  ) {
    if (type === 'new') {
      return this.http.post(
        `reservation/${data.reservation_id}/reservation_accommodation_room/${data.reservation_acc_room_id}/guest`,
        { ...data, now: this.dateFormatter.toServerFormat(new Date()) },
      );
    }
    if (type === 'delete') {
      return this.http.delete(
        `reservation/reservation_accommodation_room/guest?${generateParamArray(
          'res_acc_room_guest_id',
          data.res_acc_room_guest_ids,
        )}&force_operation=${force_operation ? 1 : 0}&hidden=${
          force_operation ? 1 : 0
        }`,
      );
    }
    if (type === 'edit') {
      return this.http.put(
        `reservation/reservation_accommodation_room/guest/${data.res_acc_room_guest_id}`,
        { ...data, now: this.dateFormatter.toServerFormat(new Date()) },
      );
    }
  }

  updateCustomerCheckinOut(res_acc_room_guest_id, checkin, checkout) {
    return this.http.put(
      `reservation/reservation_accommodation_room/guest/checkin_checkout`,
      { res_acc_room_guest_id, checkin, checkout },
    );
  }

  setKeepAccommodation(request: UpdateReservationKeepAccommodationRequest) {
    const { reservation_id, roomreservation_id, keep_accommodation } = request;

    return this.http.put(
      `reservations/${reservation_id}/accommodations/${roomreservation_id}/set_keep_accommodation`,
      { keep_accommodation },
    );
  }

  setCityTaxReportExclusion(request: UpdateCityTaxReportExclusionRequest) {
    const { reservation_id, roomreservation_id, city_tax_report_exclusion } =
      request;

    return this.http.put(
      `reservations/${reservation_id}/accommodations/${roomreservation_id}/city_tax_report_exclusion`,
      { city_tax_report_exclusion },
    );
  }

  setEntireReservationKeepAccommodation(
    request: Omit<
      UpdateReservationKeepAccommodationRequest,
      'roomreservation_id'
    >,
  ) {
    const { reservation_id, keep_accommodation } = request;

    return this.http.put(
      `reservations/${reservation_id}/set_keep_accommodation`,
      { keep_accommodation },
    );
  }

  addReservationAccommodation(data: IAddRoomRequest) {
    return this.http.post(
      `reservation/${data.reservation_id}/accommodation/${data.accommodation_id}`,
      data,
    );
  }

  changePrice(data: ReservationPriceChangeRequest) {
    return this.http.put(
      `reservation/accommodation/${data.reservation_accommodation_id}`,
      data,
    );
  }

  dailyUpdate(requests: DailyUpdateRequest[]) {
    return observableSequence(
      requests.map(({ reservation_id, ...payload }) => {
        return this.http.put(
          `reservation/${reservation_id}/update_room_day`,
          payload,
        );
      }),
    ).pipe(map((responses) => responses && responses[0]));
  }

  showCreditCard(data: IShowCreditCardRequest) {
    if (data.reservation_payment_id) {
      return this.showCreditCardPayment(data);
    }
    return this.http.post(
      `reservation/${data.related_id}/show_credit_card`,
      data,
    );
  }

  showCreditCardPayment(data: IShowCreditCardRequest) {
    return this.http.post(
      `reservation/${data.reservation_id}/payment/${data.reservation_payment_id}/show_credit_card`,
      data,
    );
  }

  updateReservationAccommodationBedType(
    reservationAccommodationId: number,
    accommodation_bed_type_combination_id: number,
  ) {
    return this.http.put(
      `reservation/accommodation/${reservationAccommodationId}`,
      { accommodation_bed_type_combination_id },
    );
  }

  calculateCityTax(reservationId: number) {
    return this.http.post(
      `reservation/${reservationId}/calculate_all_city_tax`,
      { now: this.dateFormatter.toServerFormat(new Date()) },
    );
  }

  resetCityTax(reservationId: number) {
    return this.http.delete(`reservation/${reservationId}/city_tax`);
  }
}
